







































































































































// CORE
import { Component, Mixins, Prop } from 'vue-property-decorator'

// LIBRARIES
import { Bind, Debounce } from 'lodash-decorators'
import { omit } from 'lodash'

// MIXINS
import SystemMixin from '@/mixins/SystemMixin'
import NotifyMixin from '@/mixins/NotifyMixin'

// STORE
import OrderModule from '@/store/modules/order'
import FleetOrderModule from '@/store/modules/fleet/order'
import DriverOrderModule from '@/store/modules/driver/order'

// COMPONENTS
import { ValidationProvider } from 'vee-validate'

// INTERFACES
import { CarRequestDataResource, ICarFormPart } from '@/store/types'

@Component({
  components: { ValidationProvider },
})
export default class CarFormPart extends Mixins(SystemMixin, NotifyMixin) {
  private rules = {
    select1: (value: string) => !!value ,
    select2: (value: string) => !!value ,
    select3: (value: string) => !!value ,
    select4: (value: string) => !!value,
  }

  @Prop({ default: null })
  readonly id!: number

  @Prop({ default: false })
  private isRepair!: boolean

  @Prop({ default: false })
  private extra!: boolean

  @Prop({ default: false })
  private isTO!: boolean

  private isLoading = false

  private isLoadingVIN = false
  private isLoadingStateNumber = false
  private isLoadingOrderNumber = false

  private isFilled = false

  private form: ICarFormPart = {
    vin: '',
    stateNumber: '',
    orderNumber: '',
    brand: '',
    model: '',
    stsNumber: '',
    stsSeria: '',
    productionYear : '',
  }

  private get currentMileage () {
    return this.carInfo.currentMileage
  }

  private set currentMileage (value) {
    OrderModule.setCurrentMileage(value)
  }

  private get vinList (): string[] {
    if (this.role === 'fleet' || this.role === 'mechanic') {
      return this.isTO ? FleetOrderModule.vinServiceDictionary : FleetOrderModule.vinDictionary
    } else {
      return DriverOrderModule.vinDictionary
    }
  }

  private get stateNumberList (): string[] {
    if (this.role === 'fleet' || this.role === 'mechanic') {
      return this.isTO ? FleetOrderModule.stateNumberServiceDictionary : FleetOrderModule.stateNumberDictionary
    } else {
      return DriverOrderModule.stateNumberDictionary
    }
  }

  private get orderNumberList (): string[] {
    if (this.role === 'fleet' || this.role === 'mechanic') {
      return this.isTO ? FleetOrderModule.orderNumberServiceDictionary : FleetOrderModule.orderNumberDictionary
    } else {
      return DriverOrderModule.orderNumberDictionary
    }
  }

  private get carInfo (): CarRequestDataResource {
    return OrderModule.carSearchResponse
  }

  @Bind
  @Debounce(400)
  private search (value: any, name: any) {
    let valid = false
    let query = {}

    switch (name) {
    case 'VIN':
      valid = value?.length === 17
      if (valid) {
        query = { vin: value, isRepair: this.isRepair }
        this.isLoadingVIN = true
      }
      break

    case 'stateNumber':
      valid = value?.length >= 6
      if (valid) {
        query = { stateNumber: value.replace(' ', ''), isRepair: this.isRepair }
        this.isLoadingStateNumber = true
      }
      break

    case 'orderNumber':
      valid = value?.length > 0 && value !== ''
      query = { orderNumber: value, isRepair: this.isRepair }
      this.isLoadingOrderNumber = true
      break

    default: break
    }

    if (valid) {
      this.isLoading = true

      OrderModule.setSearchParams(query)
      OrderModule.getCarInfo(+this.entity)
        .then(() => {
          this.$emit('update:id', this.carInfo.id)
          this.form = {
            ...omit(this.carInfo, ['id', 'region', 'isRepairing', 'currentMileage']),
            stateNumber: this.carInfo.stateNumber.slice(0, 6) + ' ' + this.carInfo.stateNumber.slice(6),
            stsNumber: `${this.carInfo.stsSeria} ${this.carInfo.stsNumber}`,
          }
          this.isFilled = true
          this.$emit('set-region', this.carInfo.region)
          this.$emit('set-obj', this.carInfo)
        })
        .catch(error => {
          this.notifyError(error)
          this.isFilled = false
        })
        .finally(() => {
          this.isLoading = false
          this.isLoadingVIN = false
          this.isLoadingStateNumber = false
          this.isLoadingOrderNumber = false
        })
    }
  }

  private get innerData (): any {
    return DriverOrderModule.car
  }

  private get stateNumber (): string {
    return this.innerData.stateNumber.slice(0, 6) + ' ' + this.innerData.stateNumber.slice(6)
  }

  private created () {
    if (this.id) {
      DriverOrderModule.getCarForId(this.id)
      if (this.role === 'driver') {
        this.$emit('set-obj', DriverOrderModule.car)
        this.isFilled = true
      }

      this.form = {
        vin: DriverOrderModule.car.vin,
        stateNumber: DriverOrderModule.car.stateNumber,
        orderNumber: DriverOrderModule.car.orderNumber,
        brand: DriverOrderModule.car.brand,
        model: DriverOrderModule.car.model,
        stsNumber: DriverOrderModule.car.stsNumber,
        stsSeria: DriverOrderModule.car.stsSeria,
        productionYear : DriverOrderModule.car.stsStartDate,
      }
    }
  }

  private beforeDestroy () {
    this.currentMileage = ''
  }
}
